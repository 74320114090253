import './Home.css';
import { useNavigate } from 'react-router-dom';

function Home() {
    const navigate = useNavigate();

    const handleAuth = () => {
        navigate('/app/auth');
    };

    const handleGetStarted = () => {
        navigate('/app/onboarding');
    };

    const handleTerms = () => {
        navigate('/terms');
    };

    const handlePrivacy = () => {
        navigate('/privacy');
    };

    const handleEtsy = () => {
        window.location.href = 'https://www.etsy.com/sell';
    };

    return(
        <div>
            <nav className="navbar">
                <div className="container">
                    <div className="logo">Linksy</div>
                    {/* <button onClick={handleAuth} className="cta-button">Sign In</button> */}
                </div>
            </nav>

            <header className="hero">
                <div className="container">
                    <h1>Share your shop everywhere</h1>
                    <p className="subtitle">Customize a beautiful link in bio page and storefront to grow your Etsy shop</p>
                    {/* <button onClick={handleGetStarted} className="cta-button">Get Started Free</button> */}
                </div>
            </header>

            <main>
                <section className="features">
                    <div className="container">
                        <h2>Purpose built for Etsy sellers</h2>
                        <div className="features-grid">
                            <div className="feature-card">
                                <h3>Seamless integration</h3>
                                <p>Connect your Etsy shop in one click and automatically showcase your latest products</p>
                            </div>
                            <div className="feature-card">
                                <h3>Lower fees</h3>
                                <p>Automatically convert your Etsy links to Share & Save Links to save on transaction fees</p>
                            </div>
                            <div className="feature-card">
                                <h3>Custom design</h3>
                                <p>Match your brand with custom colors, fonts, and layouts</p>
                            </div>
                            {/*
                            <div className="feature-card">
                                <h3>Growth insights</h3>
                                <p>Track clicks and understand which products drive the most interest</p>
                            </div>
                            */}
                        </div>
                    </div>
                </section>

                <section className="explanation">
                    <div className="container">
                        <div className="explanation-content">
                            <div className="explanation-text">
                                <h2>What is a Link in Bio page?</h2>
                                <p>
                                    Social media platforms like Instagram and TikTok allow few clickable links in your profile. 
                                    A Link in Bio page solves this by creating a custom landing page that showcases all your 
                                    important links in one place.
                                </p>
                                <p>
                                    For Etsy sellers, this means you can showcase your entire product catalog, seasonal collections, 
                                    and special promotions - all accessible through that single bio link. Instead of constantly 
                                    updating your profile link, your Linksy page automatically stays current with your latest listings.
                                </p>
                                <p>
                                    Plus, with Linksy's Etsy integration, your customers can browse your products and complete their 
                                    purchase seamlessly, helping you drive more sales from your social media presence.
                                </p>
                            </div>
                            <div className="explanation-image home-preview">
                                <div className="iphone-mockup">
                                    <div className="iphone-screen">
                                        <img 
                                            src="/linksy-gif-1.gif" 
                                            alt="Demo of a Linksy page showing product listings and links"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="cta">
                    <div className="container">
                        <h2>Ready to grow your shop?</h2>
                        <p>Join thousands of Etsy sellers using link in bio pages to increase their sales</p>
                        <p>Write to hello@understudy.app to join the waitlist.</p>
                        {/* <button onClick={handleGetStarted} className="cta-button">Create Your Linksy</button> */}
                    </div>
                </section>
            </main>

            <footer>
                <div className="container">
                    <div className="footer-content">
                        <div className="footer-left">
                            <p>&copy; 2024 Linksy</p>
                            <p>The term "Etsy" is a trademark of Etsy, Inc. This application uses the Etsy API but is not endorsed or certified by Etsy, Inc.</p>
                            <br />
                            <p onClick={handlePrivacy} className="clickable-item">Privacy Policy</p>
                            <p onClick={handleTerms} className="clickable-item">Terms of Service</p>
                            <p onClick={handleEtsy} className="clickable-item">Sell on Etsy</p>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    )
}

export default Home;